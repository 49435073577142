import React, {useState} from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Header from "../components/header"
import TopHat from "../components/tophat"
import Footer from "../components/footer"
import Content from "../components/content"
import Img from "gatsby-image"
import SEO from "../components/seo"
import logo from "../images/logo.png"
import roads from "../images/park_map_roads.svg"
import Trail from "../components/trail"
import trails from "../images/park_map_trails-patch.svg"
import trails_1 from "../images/park_map_trails-1.svg"
import trails_2 from "../images/park_map_trails-2.svg"
import trails_3 from "../images/park_map_trails-3.svg"
import trails_4 from "../images/park_map_trails-4.svg"
import trails_5 from "../images/park_map_trails-5.svg"
import trails_6 from "../images/park_map_trails-6.svg"
import trails_7 from "../images/park_map_trails-7.svg"
import droppin from "../images/map_pin.svg"

const ParksPage = ({data, pageContext}) => {
    const [trail, setTrail] = useState("");

    let pins = [
        { x: '69.4%', y: '26.9%', dest: '/drury-park', name: 'Drury Park' },
        { x: '61.5%', y: '33.5%', dest: '/pecan-grove-park', name: 'Pecan Grove Park' },
        { x: '47.2%', y: '35.2%', dest: '/dorset-park', name: 'Dorset Park' },
        { x: '46.4%', y: '44.8%', dest: '/trinity-hills-park', name: 'Trinity Hills Park' },
        { x: '46.3%', y: '52.9%', dest: '/bitterroot-park', name: 'Bitteroot Park' },
        { x: '57.6%', y: '54.7%', dest: '/valley-oaks-park', name: 'Valley Oaks Park' },
        { x: '49.5%', y: '63%', dest: '/mesa-verde-park', name: 'Mesa Verde Park' },
        { x: '57.5%', y: '71.3%', dest: '/sand-hills-park', name: 'Sand Hills Park' },
    ]

    let pinClass = Array(8).fill("trail-pin")

    let trailPins = [
        { x: '62.6%', y: '24.5%', name: 'Whitetail Run', id: 2 },
        { x: '45.4%', y: '34.6%', name: 'Cedar Run', id: 4 },
        { x: '45.2%', y: '45.5%', name: 'Bluebonnet Trail', id: 5 },
        { x: '50.4%', y: '59.1%', name: 'Monarch Trail', id: 3 },
        { x: '50.9%', y: '64.2%', name: 'Live Oak Loop', id: 1 },
        { x: '35.7%', y: '56%', name: 'Mockingbird Trail', id: 6 },
    ]

    let slugTable = {
    "1": "hwcid-1",
    "2": "hwcid-2",
    }

    let [trail1class,trail2class,trail3class,trail4class,trail5class,trail6class,trail7class,trail8class,trail9class] = Array(9).fill("trails-map")
    if( trail === 'Live Oak Loop') {
        trail6class = "trails-map active"
        trail4class = "trails-map active"
        trail5class = "trails-map active"
        trail8class = "trails-map active"
        pinClass[1] = "trail-pin active"
    } else if( trail === 'Whitetail Run') {
        trail4class = "trails-map active"
        trail7class = "trails-map active"
        pinClass[2] = "trail-pin active"
    } else if( trail === 'Monarch Trail') {
        trail4class = "trails-map active"
        trail1class = "trails-map active"
        trail5class = "trails-map active"
        trail8class = "trails-map active"
        pinClass[3] = "trail-pin active"
    } else if( trail === 'Cedar Run') {
        trail2class = "trails-map active"
        pinClass[4] = "trail-pin active"
    } else if( trail === 'Mockingbird Trail') {
        trail9class = "trails-map active"
        pinClass[6] = "trail-pin active"
    } else if( trail === 'Bluebonnet Trail') {
        trail4class = "trails-map active"
        trail3class = "trails-map active"
        trail8class = "trails-map active"
        pinClass[5] = "trail-pin active"
    }

    return (
        <Layout>
            <SEO title="Parks and Trails" />
            <TopHat />
            <div className="main">
                <Header district={pageContext.district}  />
                <div className="main-content">
                    <div className="title-section">
                        <small style={{display: 'inline-block',paddingBottom: '1rem'}}>Hays WCID {pageContext.district}</small>
                        <h1 className="title is-size-1">Parks and Trails</h1>
                    </div>
                    <div className="content-align" style={{minHeight: '200px'}}>
                        <p>Meticulously maintained paths surround and crisscross the entire Belterra community. The trails have been designed to connect the neighborhoods and parks as well as highlight the natural beauty of the Hill Country: serene meadows, meandering streams and seemingly endless stands of old oak trees. See map of trails and parks below.</p>
                        <p><a className="button is-primary" href="/assets/Hays%20Park%20Reservation%20Forms.pdf">Pavilion Reservation Form</a> <a className="button is-primary" href="/static/116ba204815a4a728edc93436b914254/parks_rules_and_regulations_86c54518f6.pdf">Park Rules & Regulations</a> <a className="button is-primary" href="/assets/Belterra_Trails_e422c20a13.pdf">Printable Belterra Trail Map</a></p>
                    </div>
                    <div className="parks-map">
                        <div className="base-map-container">
                            <Img fluid={data.basemap.childImageSharp.fluid} />
                        </div>
                        <img className="roads-map" src={roads} alt="" />
                        <Trail req="1" className={trail1class} />
                        <Trail req="2" className={trail2class} />
                        <Trail req="3" className={trail3class} />
                        <Trail req="4" className={trail4class} />
                        <Trail req="5" className={trail5class} />
                        <Trail req="6" className={trail6class} /> 
                        <Trail req="7" className={trail7class} />
                        <Trail req="8" className={trail8class} />
                        <Trail req="9" className={trail9class} />
                        <div className="drop-pin-wrapper">
                        { trailPins.map(pin => (
                            <div key={pin.name} className={pinClass[pin.id]} style={{ left: pin.x, top: pin.y }} onClick={() => {
                                if(trail !== pin.name) {
                                    setTrail(pin.name)
                                } else {
                                    setTrail("")
                                }
                                }} >
                                <svg className="drop-pin-svg" alt={pin.name} width="100%" height="100%" viewBox="0 0 24 35" version="1.1" xmlns="http://www.w3.org/2000/svg" style={{fillRule: 'evenodd', clipRule:'evenodd', strokeLinejoin:'round', strokeMiterlimit:'1.5'}}>
                                    <path d="M11.848,33.398c0,0 10.278,-15.085 10.278,-21.55c0,-5.673 -4.606,-10.278 -10.278,-10.278c-5.673,0 -10.278,4.605 -10.278,10.278c0,6.465 10.278,21.55 10.278,21.55Z" style={{fill:'currentColor',stroke:'#fff',strokeWidth:'0px'}}/>
                                </svg>
                                <div className="drop-pin-label">{pin.name}</div>
                            </div>
                        ))}
                        </div>
                        <div className="drop-pin-wrapper">
                        { pins.map(pin => (
                            <Link className="drop-pin" style={{ left: pin.x, top: pin.y }} to={`/${slugTable[pageContext.district]}${pin.dest}`}>
                                <svg className="drop-pin-svg" alt={pin.name} width="100%" height="100%" viewBox="0 0 24 35" version="1.1" xmlns="http://www.w3.org/2000/svg" style={{fillRule: 'evenodd', clipRule:'evenodd', strokeLinejoin:'round', strokeMiterlimit:'1.5'}}>
                                    <path d="M11.848,33.398c0,0 10.278,-15.085 10.278,-21.55c0,-5.673 -4.606,-10.278 -10.278,-10.278c-5.673,0 -10.278,4.605 -10.278,10.278c0,6.465 10.278,21.55 10.278,21.55Z" style={{fill:'currentColor',stroke:'#fff',strokeWidth:'3.14px'}}/>
                                </svg>
                                <div className="drop-pin-label">{pin.name}</div>
                            </Link>
                        ))}
                        </div>
                    </div>
                </div>
                <Footer district={pageContext.district}></Footer>
            </div>
        </Layout>
    )
}

export default ParksPage

export const query = graphql`
    query {
        basemap: file(relativePath: {eq: "park_map_base.png"}) {
            id
            childImageSharp {
                fluid(maxWidth: 2048) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
    }
`


